<template>
    <div class="page-integral-mall g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>积分商城</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="banner">
                <div class="banner-swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" @click="bannerClick(item)" v-for="(item,index) in bannerList" :key="index">
                            <img :src="item.pictureUrl" alt="">
                        </div>
                    </div>
                    <div v-if="bannerList.length==0" class="text_cen">未配置积分商品轮播图</div>
                </div>
            </div>
            <div class="select-mod">
                <div class="row">
                    <div class="tit">积分区别:</div>
                    <div class="select-row">
<!--                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全部</el-checkbox>-->
<!--                        <div style="margin: 15px 0;"></div>-->
<!--                        <el-checkbox-group style="margin-left: 0" v-model="checkedRanges" @change="handleCheckedRangesChange">-->
                            <el-checkbox @change="handleCheckedRangesChange(item,index)" v-for="(item,index) in ranges" v-model="item.checked" :label="item" :key="index">{{item.pointMin}}-{{item.pointMax}}</el-checkbox>
<!--                        </el-checkbox-group>-->

                    </div>
                </div>
                <div class="row">
                    <div class="tit">分类:</div>
                    <div class="select-row">
                        <el-checkbox :indeterminate="isIndeterminate2" v-model="checkAll2" @change="handleCheckAllChange2">全部</el-checkbox>
                        <div style="margin: 15px 0;"></div>
                        <el-checkbox-group v-model="checkedTypes" @change="handleCheckedTypesChange">
                            <el-checkbox v-for="(item,index) in types" :label="item.id" :key="index">{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="mall-list">
                <div class="item" v-for="(item,index) in integralGoodsList" :key="index">
                    <a :href="`#/IntegralMall/Detail?id=${item.id}`" class="con">
                        <div class="pic">
                            <img :src="item.pictureUrl" alt="">
                        </div>
                        <div class="text">
                            <h3>{{ item.name }}</h3>
                            <span><em>{{ item.point }}</em>积分</span>
                        </div>
                    </a>
                </div>
            </div>
            <div v-if="integralGoodsList.length==0" class="text_cen">暂无数据</div>
        </div>
        <div class="custom-pages">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevChange"
                @next-click="handleNextChange"
                :current-page="current"
                :page-sizes="[10, 50, 100]"
                :page-size="size"
                layout="total,  prev, pager, next,sizes, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import {slideshowList} from "@/api/home";
import {integralRangeList,integralGoodstypeList,integralGoodsPage} from "@/api/Product";


export default {
    name: "mall",
    data() {
        return {
            checkAll: false,
            checkAll2: false,
            checkedRanges: [],
            checkedTypes: [],
            ranges: [],
            types:['电子产品','生活用品','办公用品','实验室用品',],
            isIndeterminate: false,
            isIndeterminate2: false,

            bannerList:[], // 轮播图
            current:1,
            size:10,
            total:0,
            integralGoodsList:[], // 积分商品列表
            minPoint:'', // 最低积分
            maxPoint:'', // 最高积分


        }
    },
    mounted() {
        this.getslideshowList() // 轮播图
        this.current=1
        this.getintegralGoodsPage() // 查询商品列表
        this.getintegralRangeList() // 积分区间
        this.getintegralGoodstypeList() // 积分商品分类
    },
    methods: {
        // 积分区间全选
        handleCheckAllChange(val) {
            console.log(val)
            let list=[]
            this.ranges.forEach(i=>{
                list.push(i.id)
            })
            this.checkedRanges = val ? list : [];
            this.isIndeterminate = false;
            this.current = 1;
            this.getintegralGoodsPage() // 获取商品分页
        },
        // 积分区间勾选
        handleCheckedRangesChange(item,index) {
            // item.checked=!item.checked
            // this.ranges[index].checked=item.checked
            // this.checkedRanges=value
            let ischecked=false // 无勾选
            this.ranges.forEach(i=>{
                if(i.id!=item.id){
                    i.checked=false
                }else{
                    this.minPoint=i.pointMin // 最低积分
                    this.maxPoint=i.pointMax // 最高积分
                }
                if(i.checked){
                    ischecked=true // 至少有一个勾选
                }
            })
            if(!ischecked){ // 无勾选
                this.minPoint='' // 最低积分
                this.maxPoint='' // 最高积分
            }
            // let checkedCount = value.length;
            // this.checkAll = checkedCount === this.ranges.length;
            // this.isIndeterminate = checkedCount > 0 && checkedCount < this.ranges.length;

            // minPoint:this.minPoint,
            //     maxPoint:this.maxPoint,
            this.current = 1;
            this.getintegralGoodsPage() // 获取商品分页
        },
        // 积分商品分类全选
        handleCheckAllChange2(val) {
            let list=[]
            this.types.forEach(i=>{
                list.push(i.id)
            })
            this.checkedTypes = val ? list : [];
            this.isIndeterminate2 = false;
            this.current = 1;
            this.getintegralGoodsPage() // 获取商品分页
        },
        // 积分商品分类勾选
        handleCheckedTypesChange(value){
            let checkedCount = value.length;
            this.checkAll2 = checkedCount === this.types.length;
            this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.types.length;
            this.current = 1;
            this.getintegralGoodsPage() // 获取商品分页
        },
        initSwiper: function () {
            var that = this;
            this.$nextTick(function(){
                var swiper = new Swiper('.banner-swiper', {
                    // loop: true,
                    autoplay: {
                        delay: 3000
                    }
                });
                var swiper2 = new Swiper('.product-swiper', {
                    loop: true,
                    navigation: {
                        nextEl: ' .swiper-button-next',
                        prevEl: ' .swiper-button-prev',
                    },
                    slidesPerView: 5,
                    spaceBetween: 11,
                });
            })

        },
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getintegralGoodsPage();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getintegralGoodsPage();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getintegralGoodsPage();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getintegralGoodsPage();
        },
        // 点击轮播图
        bannerClick(item){
            console.log(item)
            // 链接类型：1 新闻中心详情页 2 产品咨询详情页 3 商品详情页 4 其他链接
            if(item.linkType==1){
                this.$router.push({path:'/NewsDetail',query: {id:item.routeUrl}}) //
            }else if(item.linkType==2){
                this.$router.push({path:'/ProductInfoDetail',query: {id:item.routeUrl}}) //
            }else if(item.linkType==3){
                this.$router.push({path:'/IntegralMall/Detail',query: {id:item.routeUrl}}) //
            }else if(item.linkType==4){
                window.open(item.routeUrl)
            }
        },
        goJFdetail(item){
            // query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
            this.$router.push({path:'/IntegralMall/Detail',query: {id:item.id}}) //
        },


    //    ————————————————————————接口请求
        getslideshowList(){ // 获取轮播图 position 0首页，1积分商城
            slideshowList({position:1}).then((res) => {
                if (res.data.code==200) {
                    this.bannerList=res.data.data.records
                    this.initSwiper()
                }
            })
        },
        getintegralRangeList(){ // 获取积分区间
            integralRangeList('').then((res) => {
                if (res.data.code==200) {
                    let data=res.data.data
                    data.forEach(i=>{
                        i.checked=false
                    })
                    function sortByArr(oddsRatio,type) {
                        return function(a, b) {
                            return type=='up' ? a[oddsRatio] - b[oddsRatio] : b[oddsRatio] - a[oddsRatio] // 升序或者降序
                        }
                    }
                    data.sort(sortByArr('sort','up'))				// sort 会直接对原数据排序
                    this.ranges=data
                }
            })
        },
        getintegralGoodstypeList(){ // 获取积分商品分类
            integralGoodstypeList('').then((res) => {
                if (res.data.code==200) {
                    this.types=res.data.data
                }
            })
        },
        getintegralGoodsPage(){ // 获取积分商品分页
            const self = this;
            clearTimeout(self.tiems);
            self.tiems = setTimeout(() => {
                console.log('获取积分商品分页',this.checkedRanges,this.checkedTypes)
            },500)
            // return
            let params={
                size:this.size,
                current:this.current,
                minPoint:this.minPoint, // 最低积分
                maxPoint:this.maxPoint, // 最高积分
				typeIds:this.checkedTypes.join(','),
            }
            integralGoodsPage(params).then((res) => {
                if (res.data.code==200) {
                    this.integralGoodsList=[]
                    if(res.data.data.records.length>0){
                        res.data.data.records.forEach((i,index)=>{
                            i.num=1
                        })
                        this.integralGoodsList=res.data.data.records
                        console.log(this.goodsList)
                        this.$forceUpdate()
                    }else{
                        if(this.current!=1){
                            this.current-- // 没查到 页数减少
                        }
                    }
                    this.total=Number(res.data.data.total)
                }
            })
        },



    }
}
</script>

<style scoped>

</style>
